import Application from "~/enums/Application";
import type {Head} from "@unhead/vue";
import {useYandexStore} from "~/stores/yandex";

export const appHead: (app: Application) => Head = function (app: Application) {
  const head: Head = {
    meta: [],
    script: []
  };

  const {yandex: {metrika: {web: webMetrikaId, mini_app: miniAppMetrikaId}} } = useAppConfig();

  let yandexMetrikaId: number | null;

  switch (app) {
    case Application.MiniApp:
      yandexMetrikaId = miniAppMetrikaId;

      break;
    default:
      yandexMetrikaId = webMetrikaId;

      head.meta?.push({
        name: 'kadam-verification',
        content: 'kadam371b355310e32ac5a77a5adf6337a025'
      });
      break;
  }

  if (yandexMetrikaId) {
    useYandexStore().setMetrikaId(yandexMetrikaId);
  }

  return head;
};